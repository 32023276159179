import Css from "./style.module.scss";

import serviceLogo from "assets/business.svg";

import { AutoTooltip } from "lib/common";
import { TableCell } from "lib/common/TableNew";
import { getProjectName } from "selectors/organizations";
import { useSelector } from "react-redux";
import React from "react";

const NameCell = ({ className, name, businessId }) => {
  const projectName = useSelector(getProjectName);

  return (
    <TableCell className={`${Css.nameCell} ${className}`}>
      <a href={`/${businessId}`}>
        <img title={projectName} alt={projectName} src={serviceLogo} />
        <div className={Css.content}>
          <AutoTooltip title={name}>
            <div className={Css.name}>
              <span>{name}</span>
            </div>
          </AutoTooltip>
        </div>
      </a>
    </TableCell>
  );
};

export default React.memo(NameCell);
