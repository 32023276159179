import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { AutoTooltip } from "lib/common";
import { NavLink } from "react-router-dom";
import { TableCell } from "lib/common/TableNew";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React from "react";
import UiRoutes from "const/UiRoutes";
import classNames from "classnames";
import moment from "moment";
import useActivityEventDescription from "hooks/useActivityEventDescription";

const { ACTIVITY } = UiRoutes;

const ActivityCell = ({ show, businessId, lastActivityEvent, className }) => {
  const { uiTexts } = useSelector(getTextsData);

  const eventDescription = useActivityEventDescription(lastActivityEvent || {});

  const { timestamp } = lastActivityEvent || {};

  return (
    <TableCell show={show} className={classNames(Css.activityCell, className, !lastActivityEvent && CommonCss.neutralText)}>
      <NavLink to={`/${businessId}${ACTIVITY}`} className={Css.content}>
        {lastActivityEvent
          ? (
            <>
              <div className={Css.text}>
                <AutoTooltip
                  className={Css.tooltip}
                  tooltipClassName={Css.tooltipClassName}>
                  {eventDescription}
                </AutoTooltip>
              </div>
              <div className={Css.date}>
                {moment.duration(moment(timestamp).diff(moment())).humanize(true)}
              </div>
            </>
          )
          : uiTexts.noActivity}
      </NavLink>
    </TableCell>
  );
};

export default React.memo(ActivityCell);
