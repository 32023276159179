import Css from "./style.module.scss";

import { Button, InputGroup, InputGroupAddon } from "shards-react";
import { DebounceInput, Form } from "lib/common";
import { FiFilter } from "react-icons/fi";
import { bind } from "decko";
import { connect } from "react-redux";
import { getEnvVars } from "selectors/envVars";
import { getTextsData } from "selectors/texts";
import React, { PureComponent } from "react";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state),
  envVars: getEnvVars(state)
});

@connect(mapStateToProps, null)
class SummaryFilter extends PureComponent {
  constructor(props) {
    super(props);

    const { envVars: { businessName } } = this.props;

    this.state = {
      businessNameEnabled: !!businessName,
      businessName: businessName || ""
    };
  }

  emitChangeEvent() {
    const { businessNameEnabled, businessName } = this.state;

    this.props.onChange({
      businessName: (businessNameEnabled && businessName) || null
    });
  }

  @bind
  handleBusinessNameInputChange({ target: { value } }) {
    this.setState({ businessName: value });
  }

  @bind
  handleBusinessNameInputComplete(value) {
    const businessName = value.trim();

    this.setState(
      () => ({
        businessName,
        businessNameEnabled: !!businessName
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleBusinessNameButtonClick() {
    this.setState(
      ({ businessNameEnabled, businessName }) => ({
        businessNameEnabled: !businessNameEnabled,
        businessName: businessNameEnabled ? "" : businessName
      }),
      () => this.emitChangeEvent()
    );
  }

  render() {
    const { textsData: { uiTexts }, disabled } = this.props;

    const { businessNameEnabled, businessName } = this.state;

    return (
      <div className={Css.summaryFilter}>
        <Form>
          <InputGroup>
            <DebounceInput
              disabled={disabled}
              value={businessName}
              placeholder={uiTexts.searchBusiness}
              onChange={this.handleBusinessNameInputChange}
              onInputComplete={this.handleBusinessNameInputComplete} />
            <InputGroupAddon type="append">
              <Button
                theme={businessNameEnabled ? "primary" : "secondary"}
                disabled={disabled || !businessName}
                onClick={this.handleToggleBusinessNameButtonClick}>
                <FiFilter />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </div>
    );
  }
}

export default SummaryFilter;
