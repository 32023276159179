import { createSelector } from "reselect";
import { getUserBusinessesData } from "selectors/businesses";

export const checkSummaryFetching = ({ summary: { fetchingData } }) => fetchingData;

export const getSummaryData = createSelector(
  [
    ({ summary: { data } }) => data,
    getUserBusinessesData
  ],
  (data, businesses) => {
    return data
      .filter(({ business: { id: businessId } }) => businesses.some(({ id }) => id === businessId))
      .sort(({ business: { id: businessIdA } }, { business: { id: businessIdB } }) => {
        return businesses.findIndex(({ id }) => id === businessIdA) - businesses.findIndex(({ id }) => id === businessIdB);
      })
      .map((item) => {
        const { business: { id: businessId } } = item;

        const found = businesses.find(({ id }) => id === businessId);

        return found ? { ...item, business: found } : item;
      });
  }
);

export const getSummaryLastUpdateDate = ({ summary: { lastUpdatedAt } }) => lastUpdatedAt;
