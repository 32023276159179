import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import googleDrive from "assets/googleDrive.svg";

import { Button } from "shards-react";
import { FaCircle } from "react-icons/fa";
import { FiBriefcase, FiPlusCircle } from "react-icons/fi";
import { Page, Preloader } from "lib/common";
import { checkSummaryFetching, getSummaryData, getSummaryLastUpdateDate } from "selectors/summary";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import Constants from "const/Constants";
import OrganizationsActions from "actions/OrganizationsActions";
import React, { useCallback, useLayoutEffect } from "react";
import SummaryActions from "actions/SummaryActions";
import SummaryFilter from "./lib/SummaryFilter";
import SummaryTable from "./lib/SummaryTable";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const SummaryPage = ({ onAddNewBusiness }) => {
  const dispatch = useDispatch();

  const [{ businessName }, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const lastUpdatedAt = useSelector(getSummaryLastUpdateDate);

  const fetchingData = useSelector(checkSummaryFetching);

  const summaryData = useSelector(getSummaryData);

  const activeOrganization = useSelector(getActiveOrganization);

  const { backupInfo: { accountConnected = false, accountEmail = "" } = {} } = activeOrganization;

  const subTitle = lastUpdatedAt
    ? `${uiTexts.updated}: ${moment.utc(lastUpdatedAt).local().format(Constants.DATETIME_FORMATS.DATETIME_TEXT)}`
    : activeOrganization.name;

  const handleSummaryFilterChange = useCallback((newFilters) => {
    setEnvVars(newFilters);
  }, [setEnvVars]);

  const handleSetupBackupButtonClick = useCallback(() => {
    dispatch(UiActions.showSetupBackupWindow());
  }, [dispatch]);

  const handleAddBusinessButtonClick = useCallback(() => {
    onAddNewBusiness();
  }, [onAddNewBusiness]);

  useLayoutEffect(() => {
    dispatch(SummaryActions.fetchSummaryData({ businessName }));

    return Utils.setInterval(
      async() => {
        await dispatch(OrganizationsActions.fetchUsersList(true, true));
        await dispatch(BusinessesActions.fetchBusinessesList(false, true, true));
        await dispatch(SummaryActions.fetchSummaryData({ businessName }, true, true));
      },
      Constants.BUSINESSES_DATA_UPDATE_INTERVAL
    );
  }, [dispatch, businessName]);

  return (
    <Page className={Css.summaryPage}>
      <header className={Css.pageHeader}>
        <div className={Css.leftBlock}>
          <div className={Css.subTitle}>{subTitle}</div>
          <div className={Css.title}>
            <FiBriefcase />
            <span>{uiTexts.businesses}</span>
          </div>
        </div>
        <div className={Css.rightBlock}>
          <SummaryFilter
            disabled={fetchingData}
            onChange={handleSummaryFilterChange} />
          {!Utils.checkIsTouchDevice() && <div>
            <Button
              size="sm"
              theme="light"
              disabled={fetchingData}
              className={Css.setupBackupButton}
              onClick={handleSetupBackupButtonClick}>
              <span>
                <img src={googleDrive} />
                <span>
                  {accountEmail
                    ? (accountConnected ? uiTexts.backupEnabled : uiTexts.backupDisabled)
                    : uiTexts.setupGoogleDriveBackup}
                </span>
                {accountEmail && <FaCircle className={accountConnected ? CommonCss.positiveText : CommonCss.negativeText} />}
              </span>
            </Button>
          </div>}
          <div>
            <Button size="sm" disabled={fetchingData} onClick={handleAddBusinessButtonClick}>
              <span>
                <FiPlusCircle />
                <span>{uiTexts.addNewBusiness}</span>
              </span>
            </Button>
          </div>
        </div>
      </header>
      <div className={Css.card}>
        {fetchingData
          ? <Preloader />
          : (summaryData.length
            ? <SummaryTable
              data={summaryData}
              disabled={fetchingData} />
            : <div className={CommonCss.noDataContent}>
              <div>
                <div><FiBriefcase /></div>
                <div>{uiTexts.noBusinesses}</div>
              </div>
            </div>)}
      </div>
    </Page>
  );
};

export default React.memo(SummaryPage);
