import Css from "./style.module.scss";

import { Button } from "shards-react";
import { TableCell } from "lib/common/TableNew";
import Constants from "const/Constants";
import React from "react";
import classNames from "classnames";

const { EMPTY_PLACEHOLDER } = Constants;

const IconCell = ({ show, emptyState, className, value, progress, href, theme, iconComponent: Icon }) => {
  const { current = 0, total = 0 } = progress ? value : { current: value };

  const disabled = (!current && !total);

  const content = progress ? `${current}/${total}` : current;

  return (
    <TableCell
      show={show}
      className={classNames(
        Css.iconCell,
        className,
        disabled && Css.noData
      )}>
      {emptyState
        ? <div className={Css.emptyState}>{EMPTY_PLACEHOLDER}</div>
        : (
          <a href={disabled ? undefined : href} title={content}>
            <Button theme={theme}>
              {!emptyState && <Icon weight={disabled ? undefined : "bold"} />}
            </Button>
            <div>{content}</div>
          </a>
        )}
    </TableCell>
  );
};

export default React.memo(IconCell);
